@import "../../scss/variables";

.main-content.side-content {
  transition: margin-left 0.3s ease;
}

.sidemenu-label {
  transition: margin-left 0.45s ease;
}

.side-menu {
  .nav-link:hover {
    .sidemenu-label {
      margin-left: 5px;
      transition: margin-left 0.45s ease;
    }

    color: $white;
  }

  .nav-item {
    transition-duration: 0.25s;
    margin: 6px 0;
  }

  

  .nav-sub {
    max-height: 0;
    overflow: hidden;
    transition: all 0.9s;
  }

  .nav-item.show > .nav-sub {
    max-height: 100vh;
    transition: all 2s;
  }

  .nav-sub-link {
    transition: all .25s;
  }
}

.iconbtn {
  display: none;
}

.side-menu {
  i.angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 12px;
  }

  .nav-item.show i.angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 12px;
  }
}

.side-badge, .main-sidebar-open.main-sidebar-hide .side-badge {
  position: absolute;
  top: 12px;
  right: 18px;
  border-radius: 50px;
}

.main-sidebar-hide .side-badge {
	position: absolute;
    top: 0px;
    right: 27px;
    border-radius: 50px;
    z-index: 4;
}

.sidemenu-logo {
  padding: 13.4px 21px;
  border-bottom: 1px solid rgba(240, 240, 255, 0.1);
  justify-content: center;
}

.side-menu .header-brand-img {
  margin-top: 0 !important;
  outline: 0;
}

.sidemenu-height {
  min-height: 72.5vh;
}

.main-footer {
  transition: margin-left 0.3s ease;
}

@media (min-width: 1200px) {
  .side-content {
    margin-left: 240px;
  }
}

@media (min-width: 992px) {
  .side-header .main-logo {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    text-align: center;
    text-align: center;
  }

  .main-sidebar-hide {
    .side-header, .main-footer {
      padding-left: 70px;
    }
  }

  .main-body {
    .side-header {
      position: fixed;
      width: 100%;
      z-index: 10 !important;
      border-bottom: 1px solid #e1e6f1;
      box-shadow: -7.829px 11.607px 20px 0px rgba(144, 143, 160, 0.09);
      left: 0;
      padding-left: 240px;
    }

    &.main-sidebar-hide .side-header {
      padding-left: 75px;
    }
  }

  .main-sidebar-hide {
    .sidemenu-logo {
      padding: 8px 6px 6px 6px;
      border-bottom: 1px solid rgba(240, 240, 255, 0.1);
      justify-content: center;
      height: 65px;
    }

    &.main-sidebar-open .sidemenu-logo {
      padding: 13.4px;
      border-bottom: 1px solid rgba(240, 240, 255, 0.1);
      justify-content: center;
    }
  }

  .main-content.side-content {
    margin-left: 240px;
  }

  .main-sidebar.side-menu {
    width: 240px;
  }

  .main-footer {
    margin-left: 0;
  }
}

@media (max-width: 992px) and (min-width: 767px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }

  .side-header .icon-logo {
    margin-left: 0;
  }

  .main-sidebar-show .side-menu {
    top: 60px !important;
  }
}

@media (min-width: 768px) {
  /*main-sidebar-hide*/

  .main-sidebar-hide {
    .side-menu {
      left: 0;
      width: 80px;
      display: block;
      transform: translateX(0);
      visibility: visible;
    }

    .main-content.side-content {
      margin-left: 80px;
    }

    .nav-label, .main-sidebar-body .sub-txt {
      display: none;
    }
  }

  .main-sidebar-body .nav-label-1 {
    height: 28px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
    color: $white;
    padding: 0;
    background: rgba(7, 167, 187, 24%);
    width: 28px;
    border-radius: 50%;
    text-align: center;
    margin: 3px 11px;
    line-height: 2.1;
  }

  .main-sidebar-hide {
    .nav-header {
      margin: 0;
    }

    &.main-sidebar-open .nav-header {
      margin: 0 0 0 15px;
    }

    .sidemenu-label {
      display: none;
      font-size: 10px;
    }

    .nav-item {
      width: 100%;
    }

    .text-icon {
      display: none;
    }

    .iconbtn {
      display: block;
    }

    .main-sidebar-body i.angle {
      display: none;
    }

    .side-menu .nav-link {
      margin: 0 auto;
      padding: 10px 0;
      text-align: center;
      margin-bottom: 0;
    }

    .main-sidebar-body .nav-item {
      + .nav-item {
        margin-top: -1px;
        text-align: center;
        margin: 6px 5px;
      }

      margin-top: -1px;
      text-align: center;
      margin: 6px 5px;
    }

    .side-menu {
      .main-logo {
        .desktop-logo {
          display: none;
        }

        .icon-logo {
          display: block;
          margin: 0 auto;
          text-align: center;
        }
      }

      .header-brand-img {
        margin-top: 0 !important;
      }
    }

    .main-sidebar-body .nav-item.show > .nav-sub {
      display: none;
    }

    .main-footer {
      margin-left: 0;
    }

    .side-menu {
      .main-sidebar-body .nav-link i {
        font-size: 20px;
      }

      i.angle {
        font-size: 12px !important;
      }
    }
  }

  /*main-sidebar-open*/

  .main-sidebar-open {
    .side-menu {
      left: 0;
      width: 240px;
    }

    .nav-label {
      display: block;
    }

    .main-sidebar-body {
      .sub-txt {
        display: block;
      }

      .nav-label {
        text-transform: uppercase;
        font-size: 11.49px;
        font-weight: 500;
        letter-spacing: .5px;
        color: $white-3;
        padding: 10px 0px 0 0;
      }

      .sub-txt {
        font-size: 10.9px;
        padding: 0 0px 0 0;
        color: $white-6;
        margin-bottom: 0;
      }
    }

    .sidemenu-label, .text-icon {
      display: block;
    }

    .iconbtn {
      display: none;
    }

    .main-sidebar-body i.angle {
      display: block;
    }

    .side-menu .nav-link {
      display: flex;
    }

    .main-sidebar-body .side-menu .nav-link {
      margin-left: 10px;
    }

    .side-menu .nav-link {
      margin-bottom: 0px;
    }

    .sidemenu-label {
      font-size: 14px;
    }

    .side-menu .main-logo {
      .desktop-logo {
        display: block;
        margin: 0 auto;
        text-align: center;
      }

      .icon-logo {
        display: none;
      }
    }

    .main-sidebar-body .nav-item.show > .nav-sub {
      display: block;
    }

    .side-menu {
      .nav-item.show i.angle {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        position: absolute;
        top: 16px;
        right: 20px;
      }

      .main-sidebar-body .nav-link i {
        margin-right: 5px;
        font-size: 18px;
      }

      i.angle {
        font-size: 12px;
      }
    }
  }
}

*::-ms-backdrop, .side-header {
  position: relative !important;
}

.main-header .select2-container .select2-selection--single .select2-selection__rEndered {
  display: block;
  padding-left: 17px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-sidebar .nav-sub-item.active .nav-sub-link {
  color: #fff !important;
}

@media (min-width: 768px) {
  .color-leftmenu.main-sidebar-hide .side-menu .main-logo {
    .desktop-logo {
      display: none !important;
    }

    .icon-logo {
      display: block !important;
      margin: 0 auto;
      text-align: center;
    }
  }

  .leftmenu.color-leftmenu.main-sidebar-hide .header-brand-img.icon-logo.theme-logo {
    display: none !important;
  }

  .color-leftmenu {
    &.main-sidebar-hide .header-brand-img {
      &.desktop-logo.theme-logo, icon-logo theme-logo {
        display: none !important;
      }
    }

    &.leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo {
      &.theme-logo {
        display: block !important;
        display: none !important;
      }

      display: none !important;
    }

    &.main-sidebar-hide.main-sidebar-open {
      .side-menu .main-logo .desktop-logo {
        display: block !important;
      }

      .main-logo .header-brand-img.desktop-logo.theme-logo {
        display: none !important;
      }
    }
  }

  .light-leftmenu.leftmenu .header-brand-img.desktop-logo {
    display: none !important;

    &.theme-logo {
      display: block !important;
    }
  }

  .leftmenu.light-leftmenu.main-sidebar-hide {
    .header-brand-img {
      &.icon-logo {
        display: none !important;
      }

      &.desktop-logo {
        &.theme-logo {
          display: none !important;
        }

        display: none !important;
      }

      &.icon-logo.theme-logo {
        display: block !important;
      }
    }

    &.dark-theme .header-brand-img {
      &.icon-logo.theme-logo {
        display: block !important;
      }

      &.desktop-logo.theme-logo {
        display: none !important;
      }
    }
  }

  .light-leftmenu.leftmenu.main-sidebar-open .header-brand-img.desktop-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide {
    &.main-sidebar-open {
      .header-brand-img.icon-logo.theme-logo {
        display: none !important;
      }

      .main-sidebar-body {
        .nav-label {
          color: #c8c4db;
        }

        .sub-txt {
          color: rgb(162, 162, 185);
        }
      }

    }

    .sidemenu-logo {
      border-bottom: 1px solid #f0f0ff;
    }
  }
}

.main-sidebar-sticky {
  background: #25233c;
}

.main-sidebar-body {
  li.nav-item.active .nav-link {
    background-color: #eaedf7;
  }

  .nav-item.active .nav-link {
    &:before, &:after {
      border-right: 20px solid #25233c;
    }

    .shape1, .shape2 {
      background: #eaedf7;
    }
  }
}
.main-sidebar{
  transition:left 0.4s ease, width 0.4s ease;

}